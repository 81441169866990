import { default as coursesCIWSXvkeoRMeta } from "/tmp/build_c2a62447/pages/courses.vue?macro=true";
import { default as indexgAeYLsQ6ogMeta } from "/tmp/build_c2a62447/pages/index.vue?macro=true";
import { default as loginrdGtibVicMMeta } from "/tmp/build_c2a62447/pages/login.vue?macro=true";
import { default as play8SxbMvZ9YrMeta } from "/tmp/build_c2a62447/pages/play.vue?macro=true";
import { default as handleLjU6mKCvnoMeta } from "/tmp/build_c2a62447/pages/settings/handle.vue?macro=true";
import { default as indexRSJcDPrWCyMeta } from "/tmp/build_c2a62447/pages/settings/index.vue?macro=true";
import { default as sign_45up2WpEV81YBEMeta } from "/tmp/build_c2a62447/pages/sign-up.vue?macro=true";
import { default as statistic0wKLSarAytMeta } from "/tmp/build_c2a62447/pages/statistic.vue?macro=true";
import { default as tournament9GWKt22CXpMeta } from "/tmp/build_c2a62447/pages/tournament.vue?macro=true";
export default [
  {
    name: coursesCIWSXvkeoRMeta?.name ?? "courses",
    path: coursesCIWSXvkeoRMeta?.path ?? "/courses",
    meta: coursesCIWSXvkeoRMeta || {},
    alias: coursesCIWSXvkeoRMeta?.alias || [],
    redirect: coursesCIWSXvkeoRMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: indexgAeYLsQ6ogMeta?.name ?? "index",
    path: indexgAeYLsQ6ogMeta?.path ?? "/",
    meta: indexgAeYLsQ6ogMeta || {},
    alias: indexgAeYLsQ6ogMeta?.alias || [],
    redirect: indexgAeYLsQ6ogMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginrdGtibVicMMeta?.name ?? "login",
    path: loginrdGtibVicMMeta?.path ?? "/login",
    meta: loginrdGtibVicMMeta || {},
    alias: loginrdGtibVicMMeta?.alias || [],
    redirect: loginrdGtibVicMMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/login.vue").then(m => m.default || m)
  },
  {
    name: play8SxbMvZ9YrMeta?.name ?? "play",
    path: play8SxbMvZ9YrMeta?.path ?? "/play",
    meta: play8SxbMvZ9YrMeta || {},
    alias: play8SxbMvZ9YrMeta?.alias || [],
    redirect: play8SxbMvZ9YrMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handleLjU6mKCvnoMeta?.name ?? "settings-handle",
    path: handleLjU6mKCvnoMeta?.path ?? "/settings/handle",
    meta: handleLjU6mKCvnoMeta || {},
    alias: handleLjU6mKCvnoMeta?.alias || [],
    redirect: handleLjU6mKCvnoMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: indexRSJcDPrWCyMeta?.name ?? "settings",
    path: indexRSJcDPrWCyMeta?.path ?? "/settings",
    meta: indexRSJcDPrWCyMeta || {},
    alias: indexRSJcDPrWCyMeta?.alias || [],
    redirect: indexRSJcDPrWCyMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45up2WpEV81YBEMeta?.name ?? "sign-up",
    path: sign_45up2WpEV81YBEMeta?.path ?? "/sign-up",
    meta: sign_45up2WpEV81YBEMeta || {},
    alias: sign_45up2WpEV81YBEMeta?.alias || [],
    redirect: sign_45up2WpEV81YBEMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statistic0wKLSarAytMeta?.name ?? "statistic",
    path: statistic0wKLSarAytMeta?.path ?? "/statistic",
    meta: statistic0wKLSarAytMeta || {},
    alias: statistic0wKLSarAytMeta?.alias || [],
    redirect: statistic0wKLSarAytMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournament9GWKt22CXpMeta?.name ?? "tournament",
    path: tournament9GWKt22CXpMeta?.path ?? "/tournament",
    meta: tournament9GWKt22CXpMeta || {},
    alias: tournament9GWKt22CXpMeta?.alias || [],
    redirect: tournament9GWKt22CXpMeta?.redirect,
    component: () => import("/tmp/build_c2a62447/pages/tournament.vue").then(m => m.default || m)
  }
]