import { type Profile, SubscriptionPlan } from '@/types/profile';
import type {
  AuthResponse,
  BridgeAuthResponse,
  LoginCredentials,
  SignUpCredentials,
  UpdateEmailCredentials,
  UpdateEmailResponse,
  UpdatePasswordCredentials,
  UpdatePasswordResponse
} from '@/types/auth';
import type { ApiResponse } from '@/composables/api/types';
import moment from 'moment/moment';
import {
  STORE_KEY,
  TOKEN_KEY,
  TRIAL_REMINDER_DAYS_INTERVAL
} from '@/stores/auth/constants';
import {
  useLogIn,
  useRefreshAuth,
  useSignInWithGoogle,
  useSignUp,
  useUpdateEmail,
  useUpdatePassword
} from '@/composables/api/actions/auth';
import {
  useProfile,
  useUpdateProfile
} from '@/composables/api/actions/profile';
import { getTokenExpiration } from '@/composables/api/helpers';
import { sleep } from '@/utils/helpers';
import { useModalStore } from '@/stores/modal';
import { useStatisticStore } from '@/stores/statistic';
import { useTournamentStore } from '@/stores/tournaments';
import AvatarSelectionModal from '@/components/Account/AvatarSelection/AvatarSelectionModal.vue';
import useIdentification from '@/composables/helpers/useIdentification';

export const useAuthStore = defineStore(
  STORE_KEY,
  () => {
    const { identifyUser, resetUserIdentification, updateUserIdentification } =
      useIdentification();
    const { openModal } = useModalStore();
    const statisticStore = useStatisticStore();
    const tournamentStore = useTournamentStore();
    const tournamentStoreRefs = storeToRefs(tournamentStore);

    const config = useRuntimeConfig();
    const router = useRouter();

    const profile = ref<Profile | null>(null);
    const token = ref<string | null>(null);
    const tokenRefreshInProgress = ref<boolean>(false);
    const gdprConsent = ref(true);

    const loggedIn = computed<boolean>(() => {
      return !!profile.value && !!token.value;
    });

    const refreshAfter = computed(() => {
      const refreshTokensEarly = config.public.refreshTokensEarly === '1';

      if (token.value && refreshTokensEarly) {
        const expiration = getTokenExpiration(token.value);
        const expirationDate = new Date(expiration).getDate();
        return new Date(expiration).setDate(
          expirationDate - parseInt(config.public.refreshTokenBeforeDays)
        );
      }
    });

    const userId = computed<string | undefined>(() => {
      if (profile.value) {
        return profile.value.userId;
      }
    });

    const userCreatedDate = computed<string>(() => {
      return moment(profile.value?.createdDate).format('MMM YYYY');
    });

    const userSubscriptionPlan = computed<SubscriptionPlan>(() => {
      return profile.value?.plan || SubscriptionPlan.Trial;
    });

    const premiumAccess = computed<boolean>(() => {
      if (profile.value) {
        return profile.value.hasPremium;
      }
      return false;
    });

    const isSubscribed = computed<boolean>(() => {
      return [SubscriptionPlan.Monthly, SubscriptionPlan.Annually].includes(
        userSubscriptionPlan.value
      );
    });

    const userPlanExpiresAt = computed<string | undefined>(() => {
      return profile.value?.planExpiresAt;
    });

    const daysUntilPlanExpiration = computed<number>(() => {
      if (userPlanExpiresAt.value) {
        return moment(userPlanExpiresAt.value).diff(moment(), 'days');
      }
      return 0;
    });

    const isTrialPlan = computed<boolean>(() => {
      return userSubscriptionPlan.value === SubscriptionPlan.Trial;
    });

    const showTrialReminder = computed<boolean>(() => {
      if (isTrialPlan.value) {
        return daysUntilPlanExpiration.value < TRIAL_REMINDER_DAYS_INTERVAL;
      }
      return false;
    });

    const userFullName = computed<string | undefined>(() => {
      if (profile.value) {
        return [profile.value.firstName, profile.value.lastName].join(' ');
      }
    });

    const userRating = computed<number | undefined>(() => {
      if (profile.value) {
        return profile.value.overallRating;
      }
    });

    const userWeeklyRating = computed<number | undefined>(() => {
      if (profile.value) {
        return profile.value.weeklyRating;
      }
    });

    const handleStreak = computed<number | undefined>(() => {
      if (profile.value) {
        return profile.value.handleStreak;
      }
    });

    async function signUp(
      credentials: SignUpCredentials
    ): Promise<ApiResponse<AuthResponse>> {
      const uuid = tournamentStoreRefs?.anonymouslyPlayedHandleId;

      const response = await useSignUp({
        ...credentials,
        uuid: toValue(uuid),
        gdprConsent: toValue(gdprConsent)
      });
      const { data } = response;

      if (data) {
        await handleAuthResponseAndRedirect(data);
        await handleAfterSignUpProcess();
      }

      return response;
    }

    async function logIn(
      credentials: LoginCredentials
    ): Promise<ApiResponse<BridgeAuthResponse>> {
      const response = await useLogIn(credentials);
      const { data } = response;

      if (data) {
        token.value = data.token;

        const { data: profileData } = await getProfile();

        if (profileData) {
          identifyUser<Profile>(profileData.userId, profileData);
        }

        router.push(config.public.homePageUrl);
      }

      return response;
    }

    async function signUpWithGoogle(
      code: string
    ): Promise<ApiResponse<AuthResponse>> {
      const uuid = tournamentStoreRefs?.anonymouslyPlayedHandleId;

      const response = await useSignInWithGoogle({
        code,
        uuid: toValue(uuid),
        gdprConsent: toValue(gdprConsent)
      });
      const { data } = response;

      if (data) {
        await handleAuthResponseAndRedirect(data);
        await handleAfterSignUpProcess();
      }

      return response;
    }

    async function loginWithGoogle(
      code: string
    ): Promise<ApiResponse<AuthResponse>> {
      const response = await useSignInWithGoogle({
        code,
        // For the case where the user created an account on the login form page
        gdprConsent: true
      });
      const { data } = response;

      if (data) {
        await handleAuthResponseAndRedirect(data);
      }

      return response;
    }

    async function logOut(): Promise<void> {
      profile.value = null;
      token.value = null;

      statisticStore.resetStore();
      tournamentStore.resetStore();

      resetUserIdentification();

      router.push('/login');
    }

    async function refreshToken(): Promise<void> {
      if (token.value) {
        tokenRefreshInProgress.value = true;
        const { data } = await useRefreshAuth();
        if (data) {
          token.value = data.token;
        }
        tokenRefreshInProgress.value = false;
      }
    }

    async function refreshAuth(): Promise<void> {
      if (token.value) {
        tokenRefreshInProgress.value = true;
        await refreshToken();
        await getProfile();
        tokenRefreshInProgress.value = false;
      }
    }

    async function getProfile(): Promise<ApiResponse<Profile>> {
      const response = await useProfile();
      const { data } = response;

      if (data) {
        profile.value = data;
      }

      return response;
    }

    async function updateProfile(
      profileData: Partial<Profile>
    ): Promise<ApiResponse<Profile>> {
      const response = await useUpdateProfile(profileData);
      const { data } = response;

      if (data) {
        profile.value = data;

        updateUserIdentification<Profile>(data);
      }

      return response;
    }

    async function updateEmail(
      credentials: UpdateEmailCredentials
    ): Promise<ApiResponse<UpdateEmailResponse>> {
      const response = await useUpdateEmail(credentials);

      if (response.data) {
        updateUserIdentification<UpdateEmailCredentials>(credentials);
      }

      return response;
    }

    async function updatePassword(
      credentials: UpdatePasswordCredentials
    ): Promise<ApiResponse<UpdatePasswordResponse>> {
      const response = await useUpdatePassword(credentials);
      const { data } = response;

      if (data?.token) {
        token.value = data.token;
      }

      return response;
    }

    async function handleAuthResponseAndRedirect(
      data: AuthResponse
    ): Promise<void> {
      token.value = data.accessToken;
      profile.value = data.profile;

      identifyUser<Profile>(data.profile.userId, data.profile);

      await router.push(config.public.homePageUrl);
    }

    async function handleAfterSignUpProcess(clearUuid = true, delay = 1000) {
      await sleep(delay);
      openModal({ modal: AvatarSelectionModal, props: { signUp: true } });

      if (clearUuid) {
        tournamentStoreRefs.anonymouslyPlayedHandleId.value = undefined;
      }
    }

    function identifyUserOnMounted() {
      const profileData = toValue(profile);

      if (profileData?.userId) {
        identifyUser<Profile>(profileData.userId, profileData);
      }
    }

    return {
      profile,
      token,
      tokenRefreshInProgress,
      gdprConsent,
      loggedIn,
      refreshAfter,
      userId,
      userCreatedDate,
      premiumAccess,
      isSubscribed,
      userPlanExpiresAt,
      daysUntilPlanExpiration,
      isTrialPlan,
      showTrialReminder,
      userFullName,
      userSubscriptionPlan,
      userRating,
      userWeeklyRating,
      handleStreak,
      signUp,
      logIn,
      signUpWithGoogle,
      loginWithGoogle,
      logOut,
      refreshToken,
      refreshAuth,
      getProfile,
      updateProfile,
      updateEmail,
      updatePassword,
      identifyUserOnMounted
    };
  },
  {
    persist: {
      paths: [TOKEN_KEY]
    }
  }
);
