import type { Ref } from 'vue';
import type { ApiResponse } from '@/composables/api/types';
import {
  type StatisticParams,
  type StatisticParamsStrict,
  type StatisticPageMeta,
  Timeframes
} from '@/types/statistic';
import { useAuthStore } from '@/stores/auth';

export const useChartData = <T>(
  params: Ref<StatisticParamsStrict>,
  meta: Ref<StatisticPageMeta>,
  getDataFn: (value?: StatisticParams) => Promise<ApiResponse<T> | undefined>
) => {
  const setParams = (values: StatisticParams = {}) => {
    params.value = {
      ...params.value,
      ...values
    };
  };

  const getChartData = async () => {
    const authStoreRefs = storeToRefs(useAuthStore());

    const loading = ref(false);

    if (!loading.value && toValue(authStoreRefs.loggedIn)) {
      loading.value = true;

      await getDataFn();

      loading.value = false;
    }
  };

  const getPageData = async () => {
    setParams({
      timeframe: Timeframes.Month,
      unitOffset: 0
    });
    await getChartData();
  };

  const updateTimeframe = async (timeframe: Timeframes) => {
    setParams({
      timeframe,
      unitOffset: 0
    });
    await getChartData();
  };

  const handleBack = async () => {
    if (meta.value.hasPreviousPage) {
      setParams({
        unitOffset: params.value.unitOffset - 1
      });
      await getChartData();
    }
  };

  const handleForward = async () => {
    if (meta.value.hasNextPage) {
      setParams({
        unitOffset: params.value.unitOffset + 1
      });
      await getChartData();
    }
  };

  return {
    setParams,
    getPageData,
    updateTimeframe,
    handleBack,
    handleForward
  };
};
