import revive_payload_client_cyZF5BcvcE from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kXyAQd8uni from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Jey92zkb8s from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_JujfAXGty8 from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt-posthog@1.5.4/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import payload_client_GzOCUKy2MP from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_wVukA8NBZF from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_75H1ZgYvJh from "/tmp/build_c2a62447/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.2_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/tmp/build_c2a62447/.nuxt/components.plugin.mjs";
import prefetch_client_VYl9HW8HYV from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_ciHzl1ipgL from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt-posthog@1.5.4/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import plugin_client_JOihwMntSg from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt-gtag@3.0.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import chunk_reload_client_8dbqpG1ggO from "/tmp/build_c2a62447/node_modules/.pnpm/nuxt@3.10.3_eslint@8.57.0_sass@1.71.1_typescript@5.4.2_vite@5.1.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_9RNjyLX3El from "/tmp/build_c2a62447/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import chart_js_client_RWHUy4jvip from "/tmp/build_c2a62447/plugins/chart-js.client.ts";
import google_signin_Z5JLcSOy93 from "/tmp/build_c2a62447/plugins/google-signin.ts";
import plyr_client_AWuhaKXXrg from "/tmp/build_c2a62447/plugins/plyr.client.ts";
import sentry_client_shVUlIjFLk from "/tmp/build_c2a62447/plugins/sentry.client.ts";
import vue_toastification_client_F29MfCWaUJ from "/tmp/build_c2a62447/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_cyZF5BcvcE,
  unhead_kXyAQd8uni,
  router_Jey92zkb8s,
  posthog_client_JujfAXGty8,
  payload_client_GzOCUKy2MP,
  check_outdated_build_client_wVukA8NBZF,
  plugin_vue3_75H1ZgYvJh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VYl9HW8HYV,
  directives_ciHzl1ipgL,
  plugin_client_JOihwMntSg,
  chunk_reload_client_8dbqpG1ggO,
  plugin_9RNjyLX3El,
  chart_js_client_RWHUy4jvip,
  google_signin_Z5JLcSOy93,
  plyr_client_AWuhaKXXrg,
  sentry_client_shVUlIjFLk,
  vue_toastification_client_F29MfCWaUJ
]