import type { GameSettings } from '@/types/settings';

export interface Role {}

export enum SubscriptionPlan {
  Trial = 'trial',
  Monthly = 'monthly',
  Annually = 'annually'
}

export interface SubscriptionData {
  hasPremium: boolean;
  plan: SubscriptionPlan;
  planExpiresAt?: string;
  stripeCustomerId?: string;
}
export enum AvatarColor {
  Orange = 'orange',
  Green = 'green',
  Red = 'red',
  Purple = 'purple',
  Blue = 'blue'
}

export enum AvatarImage {
  Alien = 'alien',
  Cowboy = 'cowboy',
  Monkey = 'monkey',
  Cat = 'cat',
  Robot = 'robot',
  Clown = 'clown',
  Angel = 'angel',
  Ghost = 'ghost',
  Devil = 'devil',
  Crown = 'crown'
}

export interface GameStats {
  weeklyRating: number;
  overallRating: number;
  handleStreak: number;
}

export interface Achievements {
  totalGamesPlayed: number;
  totalWins: number;
  bonusTricks: number;
}

export interface Profile
  extends GameSettings,
    GameStats,
    Achievements,
    SubscriptionData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  roles: Role[];
  avatarColor: AvatarColor;
  avatarImage: AvatarImage;
  createdDate: string;
  gdprConsent?: boolean;
}
